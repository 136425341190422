<div class="modal-header" *ngIf="IsModal" [ngClass]="DynamicFields?.modalTitleClass ?? ''">
  <h5 class="modal-title" id="editUserLabel">{{ DynamicFields.Label }}</h5>

  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div [ngClass]="{'': IsModal, 'card': !IsModal }" *ngIf="DynamicFields?.campi || (Item?.type == 'E' && Item?.tipo == 'LA' && FromLista)">
  <div [ngClass]="{'modal-body': IsModal, 'card-body': !IsModal }">
    <app-field-viewer [(Oggetto)]="Item" [Campi]="DynamicFields.campi" (CustomButton)="customButton(Item, $event)" (OnChange)="ChangeHandler($event)"></app-field-viewer>
    <div *ngIf="Item?.type == 'E' && Item?.tipo == 'LA' && FromLista">
      <div class="d-md-flex align-items-center p-3 ng-star-inserted" style="background-color: #87858525;">
        <span class="d-flex align-items-center p-2 ng-star-inserted">
          <h5 class="mb-0 mr-1 text-dark font-weight-bold font-14">Attività</h5>
        </span><!---->
      </div>
      <app-dynamic-col-table (Refresh)="Refresh()" [(columns)]="Item.figli" [(rows)]="Item.clientiObject" [hasSave]="true"
        (onSaveClick)="UpdateAttivitas()"></app-dynamic-col-table>
    </div>
  </div>
</div>

<div [ngClass]="{'': IsModal, 'card': !IsModal }" *ngIf="DynamicFields.sezioni && !ResetFields">
  <div [ngClass]="{'modal-body': IsModal, 'card-body': !IsModal }">
    <app-sector-viewer [(DropdownSources)]="DropdownSources" (OnFilter)="FilterHandler($event)"
                       (OnChange)="ChangeHandler($event)" [(Campi)]="DynamicFields.sezioni"
                       [(Oggetto)]="Item"></app-sector-viewer>

  </div>
</div>
<div class="d-md-flex align-items-center justify-content-end m-b-10 m-r-10">
  <ng-container *ngIf="!HideButtons">
    <ng-container *ngFor="let button of DynamicFields['Azioni']">
      <button type="submit" type="button" [ngClass]="button['class']" [ngbTooltip]="button.placeholder ?? ''"
              *ngIf=" (!button.hideOnSubtab || !FromLista) && (button.nome != 'Elimina' || !HideDelete ) && permission.HasCapacity(button['capacita']) && campoUtility.ButtonShow(button,Item)"
              (click)='customButton(Item,button)'>
        <i *ngIf="button['icon']" [ngClass]="button['icon']" class="font-14 mr-1 text-white"></i>
        <span [ngClass]="button['labelClass']"> {{ button['nome'] | translate }} </span>
      </button>
    </ng-container>
  </ng-container>
  <button type="submit" (click)="save(ExternalAdd)" style="margin: 0.75px;" class=" btn btn-success  waves-effect waves-light">
    <span class="mr-1 text-white "> Salva </span>
    <i class="far fa-save text-white"></i>
  </button>
  <button type="submit" *ngIf="!ExternalAdd" (click)="save(true)" style="margin: 0.75px;"
          class=" btn btn-success  waves-effect waves-light">
    <span class="mr-1 text-white "> Salva e chiudi </span>
    <i class="far fa-save text-white"></i>
  </button>
  <button type="submit" (click)="close()" style="margin: 0.75px;" class=" btn btn-danger  waves-effect waves-light">
    <span class="mr-1 text-white "> Chiudi </span>
    <i class="far fa-window-close text-white"></i>
  </button>
  <app-stampa [Stampe]="Stampe" *ngIf="Stampe?.length > 0"></app-stampa>
</div>
