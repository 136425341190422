<!-- <div class="row col-lg-12">
    <div class="col-lg-12">
        <label>{{nameCategoria1}}</label>
        <ng-multiselect-dropdown [settings]="dropSetting" [placeholder]="'Seleziona'" [data]="categorie" [(ngModel)]="selectedCategoria" (ngModelChange)="categoriaChange()"></ng-multiselect-dropdown>
    </div>
    <div *ngIf="selectedCategoria && selectedCategoria?.figli.length > 0" class="col-lg-12">
        <label>{{nameCategoria2}}</label>
        <ng-multiselect-dropdown [settings]="dropSetting" [placeholder]="'Seleziona'" [data]="sottoCategoria" [(ngModel)]="selectedSottoCategoria"(ngModelChange)="sottoCategoriaChange()" ></ng-multiselect-dropdown>
    </div>
    <div *ngIf="selectedSottoCategoria && selectedSottoCategoria?.figli.length > 0" class="col-lg-12">
        <label>{{nameCategoria3}}</label>
        <ng-multiselect-dropdown  [settings]="dropSetting" [placeholder]="'Seleziona'" [data]="prestazione" [(ngModel)]="selectedPrestazione" (ngModelChange)="prestazioneChange()"></ng-multiselect-dropdown>
    </div>
</div> -->



<div class="row col-lg-12" style="padding-inline: 0;">
    <div class="col-lg-12 mb-2" style="padding-right: 0;">
        <div class="input-group" style="align-items: center">
            <div class="input-group-prepend">
                <label class="input-group-text">
                    {{nameCategoria1}}
                </label>
            </div>
            <ng-multiselect-dropdown [settings]="dropSetting" [placeholder]="'Seleziona'" [data]="categorie"
                [(ngModel)]="selectedCategoria" (ngModelChange)="categoriaChange()"></ng-multiselect-dropdown>
        </div>
    </div>
    <div class="col-lg-12 mb-2"  style="padding-right: 0;" *ngIf="selectedCategoria && selectedCategoria?.figli.length > 0">
        <div class="input-group" style="align-items: center" >
            <div class="input-group-prepend">
                <label class="input-group-text">
                    {{nameCategoria2}}
                </label>
            </div>
            <ng-multiselect-dropdown [settings]="dropSetting" [placeholder]="'Seleziona'" [data]="sottoCategoria"
                [(ngModel)]="selectedSottoCategoria" (ngModelChange)="sottoCategoriaChange()"></ng-multiselect-dropdown>
        </div>
    </div>
    <div class="col-lg-12 mb-2"  style="padding-right: 0;" *ngIf="selectedSottoCategoria && selectedSottoCategoria?.figli.length > 0">
        <div class="input-group" style="align-items: center">
            <div class="input-group-prepend">
                <label class="input-group-text">
                    {{nameCategoria3}}
                </label>
            </div>
            <ng-multiselect-dropdown [settings]="dropSetting" [placeholder]="'Seleziona'" [data]="prestazione"
                [(ngModel)]="selectedPrestazione" (ngModelChange)="prestazioneChange()"></ng-multiselect-dropdown>
        </div>
    </div>
</div>


